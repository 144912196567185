import React, { useEffect } from "react";
import {
  Home,
  Box,
  Tag,
  Camera,
  Archive,
  ShoppingBag,
  TrendingUp,
  Gift,
  User,
  HelpCircle,
  Users,
  Flag,
  CreditCard,
  Key,
  Settings,
} from "react-feather";
import { GrUserAdmin } from "react-icons/gr";

export const MENUITEMS = localStorage.getItem("virtualUserToken")
  ? !localStorage.getItem("virtualStoreId")
    ? []
    : [
        {
          path: "/virtual-store/dashboard",
          title: "Virtual Store Home",
          icon: Home,
          type: "link",
          badgeType: "primary",
          active: false,
        },
        {
          path: "/virtual-store/stock-management",
          title: "Stock Management",
          icon: Archive,
          type: "link",
          badgeType: "primary",
          active: false,
        },
        {
          path: "/virtual-store/order-list",
          title: "Order Management",
          icon: ShoppingBag,
          type: "link",
          badgeType: "primary",
          active: false,
        },
        {
          title: "Promo Management",
          icon: Tag,
          type: "sub",
          active: false,
          children: [
            {
              path: "/virtual-store/promo/list-promo",
              title: "List Promo",
              type: "link",
            },
            {
              path: "/virtual-store/promo/create-promo",
              title: "Create Promo",
              type: "link",
            },
          ],
        },
      ]
  : [
      {
        path: "/dashboard",
        title: "Home",
        icon: Home,
        type: "link",
        badgeType: "primary",
        active: false,
      },
      {
        title: "User",
        icon: User,
        type: "sub",
        active: false,
        children: [
          {
            permissionId: ["List User"],
            path: "/users/all-user",
            title: "List User",
            type: "link",
          },
          {
            permissionId: ["List User"],
            path: "/users/beyond-star-user",
            title: "Beyond Star User",
            type: "link",
          },
          {
            permissionId: ["Create User"],
            path: "/users/create-user",
            title: "Create User",
            type: "link",
          },
          {
            permissionId: ["Create Sales Group", "Edit Sales Group"],
            path: "/users/sales-group",
            title: "List Sales Group",
            type: "link",
          },
        ],
      },
      {
        title: "Content",
        icon: Camera,
        type: "sub",
        active: false,
        children: [
          {
            permissionId: ["Home Banner"],
            path: "/content/home-banner",
            title: "Home Banner",
            type: "link",
          },
          {
            permissionId: ["Highlight Banner"],
            path: "/content/highlight-banner",
            title: "Highlight Banner",
            type: "link",
          },
          {
            permissionId: ["Landing Page Banner"],
            path: "/content/landing-banner",
            title: "Landing Page Content",
            type: "link",
          },
          {
            permissionId: ["Reseller Page Banner"],
            path: "/content/reseller-banner",
            title: "Reseller Page Banner",
            type: "link",
          },
          {
            permissionId: ["Product Showcase Highlight"],
            path: "/content/list-productshowcase",
            title: "Product Showcase Highlight",
            type: "link",
          },
          {
            permissionId: ["Testimony"],
            path: "/content/testimony",
            title: "Testimony",
            type: "link",
          },
        ],
      },
      {
        title: "Products",
        icon: Box,
        type: "sub",
        active: false,
        children: [
          {
            permissionId: ["All Product"],
            path: "/products/all-products",
            title: "All Product",
            type: "link",
          },
          {
            permissionId: ["Add product"],
            path: "/products/add-product",
            title: "Add Product",
            type: "link",
          },
          {
            permissionId: ["Categories"],
            path: "/products/categories",
            title: "Categories",
            type: "link",
          },
          {
            permissionId: ["Product Package"],
            path: "/products/package-recommendation",
            title: "Product Package",
            type: "link",
          },
          {
            permissionId: ["Product Tagging"],
            path: "/products/tagging",
            title: "Product Tagging",
            type: "link",
          },
        ],
      },
      {
        title: "Stock Management",
        icon: Archive,
        type: "sub",
        active: false,
        children: [
          {
            permissionId: ["Create Warehouse"],
            path: "/warehouse/create-warehouse",
            title: "Create Warehouse",
            type: "link",
          },
          {
            permissionId: ["List Warehouse"],
            path: "/warehouse/list-warehouse",
            title: "List Warehouse",
            type: "link",
          },
        ],
      },
      {
        title: "Order",
        icon: ShoppingBag,
        type: "sub",
        active: false,
        children: [
          {
            permissionId: ["List Order"],
            path: "/order-list",
            title: "List Order",
            type: "link",
          },
        ],
      },
      {
        title: "Growth Point",
        icon: TrendingUp,
        type: "sub",
        active: false,
        children: [
          {
            permissionId: ["All Growth Point"],
            path: "/growth-point/list-gp",
            title: "All Growth Point",
            type: "link",
          },
        ],
      },
      {
        title: "Promo",
        icon: Tag,
        type: "sub",
        active: false,
        children: [
          {
            permissionId: ["List Promo"],
            path: "/promo/list-promo",
            title: "List Promo",
            type: "link",
          },
          {
            permissionId: ["Create Promo"],
            path: "/promo/create-promo",
            title: "Create Promo",
            type: "link",
          },
        ],
      },
      {
        title: "Commission",
        icon: Gift,
        type: "sub",
        active: false,
        children: [
          {
            permissionId: ["List Commission"],
            path: "/commission/list-commission",
            title: "List Commission",
            type: "link",
          },
        ],
      },
      {
        title: "Community",
        icon: Users,
        type: "sub",
        active: false,
        children: [
          {
            permissionId: ["List Community"],
            path: "/community/list-community",
            title: "Community",
            type: "link",
          },
          {
            permissionId: ["Community Suggestion"],
            path: "/community/list-suggestion",
            title: "Community Suggestion",
            type: "link",
          },
        ],
      },
      {
        title: "Help Center",
        icon: HelpCircle,
        type: "sub",
        active: false,
        children: [
          {
            permissionId: ["Feedback"],
            path: "/help-center/feedback",
            title: "Feedback",
            type: "link",
          },
          {
            permissionId: ["Article"],
            path: "/help-center/article",
            title: "Article",
            type: "link",
          },
          {
            permissionId: ["Subtopic"],
            path: "/help-center/subtopic",
            title: "Subtopic",
            type: "link",
          },
          {
            permissionId: ["FAQ"],
            path: "/help-center/faq",
            title: "FAQ",
            type: "link",
          },
        ],
      },
      {
        title: "Admin",
        icon: GrUserAdmin,
        type: "sub",
        active: false,
        children: [
          {
            permissionId: ["List Admin"],
            path: "/admin/list-admin",
            title: "List Admin",
            type: "link",
          },
        ],
      },
      {
        title: "Roles",
        icon: Key,
        type: "sub",
        active: false,
        children: [
          {
            permissionId: ["Create Role"],
            path: "/roles/view-roles",
            title: "List Roles",
            type: "link",
          },
        ],
      },
      {
        title: "Campaign",
        icon: Flag,
        type: "sub",
        active: false,
        children: [
          {
            permissionId: ["List Campaign"],
            path: "/campaign/list-campaign",
            title: "List Campaign",
            type: "link",
          },
          {
            permissionId: ["List Promo Campaign"],
            path: "/campaign/list-promo-campaign",
            title: "List Promo Campaign",
            type: "link",
          },
        ],
      },
      {
        title: "Metadata",
        icon: CreditCard,
        type: "sub",
        active: false,
        children: [
          {
            permissionId: ["List Metadata"],
            path: "/metadata/list-metadata",
            title: "List Metadata",
            type: "link",
          },
        ],
      },
      {
        title: "Redemption",
        icon: Gift,
        type: "sub",
        active: false,
        children: [
          {
            permissionId: ["Annual Reward", "Quarterly Reward"],
            path: "/redemption/list-redemption",
            title: "List Redemption",
            type: "link",
          },
        ],
      },
      {
        title: "Configuration",
        icon: Settings,
        type: "sub",
        active: false,
        children: [
          {
            path: "/reseller-level/list-reseller-level",
            title: "List Reseller Management",
            type: "link",
          },
        ],
      },
      {
        title: "Virtual Store",
        icon: Settings,
        type: "sub",
        active: false,
        children: [
          {
            path: "/virtual-store-user/list-promotor",
            title: "List Promotor",
            type: "link",
          },
        ],
      },
      {
        title: "Downline Management",
        icon: Settings,
        type: "sub",
        active: false,
        children: [
          {
            path: "/downline-management/list-downline",
            title: "List Downline",
            type: "link",
          },
        ],
      },
    ];
