// warehouse
export const SET_SHOWPOPUP = "SET_SHOWPOPUP";
export const SET_PROVINCE = "SET_PROVINCE";
export const SET_CITY = "SET_CITY";
export const SET_DISTRICT = "SET_DISTRICT";
export const SET_URBAN = "SET_URBAN";
export const SET_WAREHOUSE = "SET_WAREHOUSE";
export const SET_WAREHOUSE_DETAIL = "SET_WAREHOUSE_DETAIL ";
export const SET_WAREHOUSE_PRODUCT_INCLUDE = "SET_WAREHOUSE_PRODUCT_INCLUDE";
export const SET_WAREHOUSE_PRODUCT_EXCLUDE = "SET_WAREHOUSE_PRODUCT_EXCLUDE";

// order
export const SET_ORDER_LIST = "SET_ORDER_LIST";
export const SET_ALL_ORDER_LIST = "SET_ALL_ORDER_LIST";
export const SET_PROMO = "SET_PROMO";
export const SET_SINGLE_PROMO = "SET_SINGLE_PROMO";
export const SET_ORDER_DETAIL = "SET_ORDER_DETAIL";
export const SET_REQUEST_DETAIL = "SET_REQUEST_DETAIL";

// product-showcase
export const GET_PRODUCT_SHOWCASE = "GET_PRODUCT_SHOWCASE";
export const GET_PRODUCT_SHOWCASE_EDIT = "GET_PRODUCT_SHOWCASE_EDIT";
export const UPDATE_PRODUCT_SHOWCASE = "UPDATE_PRODUCT_SHOWCASE";
export const POST_NEW_PRODUCT_SHOWCASE = "POST_NEW_PRODUCT_SHOWCASE";
export const SET_IMAGE_DESKTOP_PRODUCT_SHOWCASE =
  "SET_IMAGE_DESKTOP_PRODUCT_SHOWCASE";
export const SET_IMAGE_MOBILE_PRODUCT_SHOWCASE =
  "SET_IMAGE_MOBILE_PRODUCT_SHOWCASE";
export const SET_MOVE_UP_PRODUCT_SHOWCASE = "SET_MOVE_UP_PRODUCT_SHOWCASE";
export const SET_MOVE_DOWN_PRODUCT_SHOWCASE = "SET_MOVE_DOWN_PRODUCT_SHOWCASE";
export const SET_IMAGE_PRODUCT_SHOWCASE = "SET_IMAGE_PRODUCT_SHOWCASE";
export const EMPTY_SHOWCASE_STATE = "EMPTY_SHOWCASE_STATE";

// growth-point
export const SET_GROWTH_POINT = "SET_GROWTH_POINT";
export const SET_GROWTH_POINT_DETAIL = "SET_GROWTH_POINT_DETAIL";
export const SET_GP_DETAIL_USER = "SET_GP_DETAIL_USER";
export const SET_GP_USER_TOTAL_GP = "SET_GP_USER_TOTAL_GP";
export const POST_GROWTH_POINT = "POST_GROWTH_POINT";
export const SET_GP_USER_BY_REFERRAL = "SET_GP_USER_BY_REFERRAL";
export const SET_EMPTY_GP_USER = "SET_EMPTY_GP_USER";
export const SET_GROWTH_POINT_LOG = "SET_GROWTH_POINT_LOG";

// product review
export const SET_PRODUCT_REVIEW_APPROVAL = "SET_PRODUCT_REVIEW_APPROVAL";
export const SET_PRODUCT_REVIEW_DATA = "SET_PRODUCT_REVIEW_DATA";

// comission
export const SET_USER_COMMISSION = "SET_USER_COMMISSION";
export const SET_COMMISSION = "SET_COMMISSION";
export const SET_ALL_COMMISSION = "SET_ALL_COMMISSION";
export const SET_TOTAL_COMMISSION = "SET_TOTAL_COMMISSION";
export const SET_REVIEWED_PRODUCT_DATA = "SET_REVIEWED_PRODUCT_DATA";
export const SET_DETAIL_COMMISSION = "SET_DETAIL_COMMISSION";
export const SET_COMMISSION_MANAGEMENT = "SET_COMMISSION_MANAGEMENT";
export const SET_ALL_UNPAID_COMMISSION = "SET_ALL_UNPAID_COMMISSION";
export const SET_TOTAL_UNPAID_COMMISSION = "SET_TOTAL_UNPAID_COMMISSION";

//users
export const SET_USER_LIST = "SET_USER_LIST";
export const SET_USER_DETAIL_DATA = "SET_USER_DETAIL_DATA";
export const SET_USER_ALL = "SET_USER_ALL";
export const CLEAN_USER_DETAIL_DATA = "CLEAN_USER_DETAIL_DATA";
export const SET_USER_COMMUNITY = "SET_USER_COMMUNITY";
export const SET_CREATE_USER = "SET_CREATE_USER";
export const SET_PATCH_USER = "SET_PATCH_USER";
export const CLEAR_USER_STATE = "CLEAR_USER_STATE";
export const SET_USER_STATS = "SET_USER_STATS";
export const SET_BEYOND_STAR_USER__STATS = "SET_BEYOND_STAR_USER__STATS";
export const SET_USER_BADGE_PROGRAM = "SET_USER_BADGE_PROGRAM";
export const SET_USER_BEYOND_STAR = "SET_USER_BEYOND_STAR";
export const SET_USER_BEYOND_STAR_ALL = "SET_USER_BEYOND_STAR_ALL";
export const SET_SALES_GROUP_LIST = "SET_SALES_GROUP_LIST";
export const SET_SALES_GROUP_LOADING = "SET_SALES_GROUP_LOADING";
export const SET_RESPONSE_ASSIGN_SALES_GROUP = "SET_RESPONSE_ASSIGN_SALES_GROUP";

// FAQ
export const SET_FAQ_LIST = "SET_FAQ_LIST";
export const DELETE_FAQ = "DELETE_FAQ";
export const CREATE_FAQ = "CREATE_FAQ";
export const EDIT_FAQ = "EDIT_FAQ";

// SIDEBAR
export const RESET_SIDEBAR = "RESET_SIDEBAR";

//Service
export const GET_SERVICES = "GET_SERVICES";
export const EDIT_REDEMPTION_REWARD = "EDIT_REDEMPTION_REWARD";

// Restrict Role
export const SET_ADMIN_ROLE = "SET_ADMIN_ROLE";
export const SET_RESELLER_LEVEL_LIST = "SET_RESELLER_LEVEL_LIST";

//Virtual Store
export const SET_VIRTUAL_STORE_DATA = "SET_VIRTUAL_STORE_DATA";
export const SET_VIRTUAL_STOCK_DATA = "SET_VIRTUAL_STOCK_DATA";
/// CRUD Virtual Store
export const SET_VIEW_SINGLE_VIRTUAL_STORE = "SET_VIEW_SINGLE_VIRTUAL_STORE";
export const SET_SEND_DATA_VIRTUAL_STORE = "SET_SEND_DATA_VIRTUAL_STORE";
export const SET_VIEW_SINGLE_VIRTUAL_STORE_PRODUCT =
  "SET_VIEW_SINGLE_VIRTUAL_STORE_PRODUCT";
// Virtual Store
export const SET_IS_CHOOSING_STORE = "SET_IS_CHOOSING_STORE";
export const LOGIN_VIRTUAL_STORE = "LOGIN_VIRTUAL_STORE";
export const FETCH_ALL_VIRTUAL_STORE_LIST = "FETCH_ALL_VIRTUAL_STORE_LIST";

export const SET_VIRTUAL_ALL_ORDER_LIST = "SET_VIRTUAL_ALL_ORDER_LIST";
export const SET_VIRTUAL_ORDER_LIST = "SET_VIRTUAL_ORDER_LIST";
export const SET_VIRTUAL_ORDER_DETAIL = "SET_VIRTUAL_ORDER_DETAIL";
export const SET_VIRTUAL_REQUEST_DETAIL = "SET_VIRTUAL_REQUEST_DETAIL";
